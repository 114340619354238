import { createApp } from 'vue';
import App from './App';
import { assign } from 'lodash';
import { ConfigurationService } from '@nsftx/games-config';
import gamesStatic from '@nsftx/games-sdk-js/src/api/gamesStatic';
import sentry from '@nsftx/games-sdk-js/src/utility/sentry';
import { webViewIntegration } from './utility';
import router from './router';
import store from './store';

const qp = new URLSearchParams(window.location.search);
const appName = qp.get('application') || 'web';
const tenantId = qp.get('tenantId');
const messageProtocol = qp.get('messageProtocol') || qp.get('integrationType') || 'gravityGateway';
const deliveryPlatform = qp.get('deliveryPlatform') || qp.get('application') || 'Web';
const token = qp.get('token');
const playerId = qp.get('id') || qp.get('playerId');
const clientType = qp.get('authStrategy') || qp.get('clientType');
let productUuid = null;

window.addEventListener('message', (event) => {
  if (event.data.action === 'Slave.Load') {
    productUuid = event.data.data.account.uuid;
  }
});

(async () => {
  const configService = new ConfigurationService({
    environment: process.env.NODE_ENV,
    requiredPaths: [],
    applicationName: appName,
    messageProtocol,
    clientAdapters: [
      {
        name: 'GravityGatewayAdapter',
        options: {
          slaveId: 'PluginNextSix',
        },
      },
    ],
    translationDomains: ['Web', 'Mobile', 'ngs'],
    ui: tenantId,
  });
  const config = await configService.getConfiguration();
  const webTrans = config.translations.Web;
  const mobileTrans = config.translations.Mobile;
  const ngsTrans = config.translations.ngs;
  const mergedTrans = {
    ...webTrans,
    ...mobileTrans,
    ...ngsTrans,
  };
  const translations = await gamesStatic.getTranslations(config.locale);
  await store.dispatch('setTranslations', assign(translations, mergedTrans));
  await store.dispatch('setConfig', config);
  await store.dispatch('setDeviceLayout');
  store.state.config.channel = 'web';
  const isPerBet = config.paymentStrategy[deliveryPlatform.toLowerCase()].value === 'perBet';
  const paymentStrategy = {
    paymentStrategy: config.paymentStrategy,
  };
  const mergedRules = { ...config.rules, ...paymentStrategy };
  const betslipConfig = {
    betLayout: 'E',
    channel: config.applicationName,
    ticketTypes: ['single'],
    rules: mergedRules,
    taxes: config.taxes,
    isPossibleWinActive: true,
    isPaymentBetAllowed: isPerBet,
    isPaymentPerBetAllowed: isPerBet,
    isFuturePerBetAllowed: false,
    isFuturePerTicketAllowed: false,
    isTicketCancelAllowed: false,
    isTicketRebetAllowed: false,
    minIncrement: 0.01,
    futureRounds: 1,
  };
  await store.dispatch('setRules', mergedRules);
  await store.dispatch('gamesBetslip/setConfig', betslipConfig);
  store.state.config.productUuid = productUuid;
  if (token && config.ui.config.mode === 'standalone') {
    store.state.config.platformConfig.clientType = clientType;
    store.state.config.platformConfig.playerId = playerId;
    await store.dispatch('getPlayer', { config, token });
  }

  const app = createApp(App, {
    extends: App,
    beforeCreate() {
      if (webViewIntegration.isActive()) {
        window.WebAppListener.sendMessage('Slave.Init');
      }
    },
  });

  if (process.env.VUE_APP_SENTRY_ACTIVE === 'true') {
    sentry.start({
      app,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      sampleRate: 0.01,
      trackComponents: true,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'Network Error',
        'timeout of 5000ms exceeded',
        'timeout of 10000ms exceeded',
        'Android is not defined',
        /.*NotAllowedError.*/gm,
        /.*WakeLock.*/gm,
      ],
    });
  }

  app.config.productionTip = false;
  app.use(router).use(store).mount('#app');
})();
